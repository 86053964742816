import * as React from "react"
import {useEffect} from "react";

import {Card} from "./Card";
// @ts-ignore
import configData from '../data/gear-ratios.json';
import {type} from "os";
import {CancelIcon} from "../icons/CancelIcon";

export type BikeType = "MTB" | "Race" | "Gravel";

export interface ChainringSet {
    bikeType: BikeType;
    brand: string;
    name: string;
    set?: number[];
}

export interface CogSet {
    bikeType: BikeType;
    description: string;
    name: string;
    set?: number[];
}

export interface TireSize {
    bikeType: BikeType;
    ETRTO: string;
    inch: string;
    width: string;
    description?: string;
}

export interface GearRatio {
    chainringSet?: ChainringSet;
    cogSet?: CogSet;
    tireSize?: TireSize;
}

interface GearRatiosProps {
    bikeTypes: BikeType[];
    boxId: string;
    hideBox: (boxId: string) => void
}

export const GearRatios = (gearRatiosProps: GearRatiosProps) => {

    const [gearRatio, setGearRatio] = React.useState({} as GearRatio);

    const chainringSets: ChainringSet[] = configData.ChainringSets
    const [chainringSetSize, setChainringSetSize] = React.useState(0)
    const [chainringSetRestriction, setChainringSetRestriction] = React.useState(0)

    const cogSets: CogSet[] = configData.CogSets
    const [cogSetSize, setCogSetSize] = React.useState(0)
    const [cogSetRestriction, setCogSetRestriction] = React.useState(0)

    const [tireSizes, setTireSizes] = React.useState(configData.TireSizes as TireSize[])
    const [tireSize, setTireSize] = React.useState(0)

    const [wheelSizes, setWheelSizes] = React.useState(
        tireSizes.filter((value, index, self) =>
            index === self.findIndex(t => t.inch === value.inch && t.bikeType === value.bikeType)
        ))

    const [lightest, setLightest] = React.useState(0)
    const [heaviest, setHeaviest] = React.useState(0)

    const changeChainringSetRestriction = (value: string) => {
        const dropDown = document.getElementById("chainringSet" + gearRatiosProps.boxId) as HTMLSelectElement
        if (dropDown !== null) {
            dropDown.selectedIndex = 0;
        }
        setChainringSetRestriction(value && parseInt(value) ? parseInt(value) : 0)
    }
    const changeChainringSetsState = (value: string) => {
        const newChainringSet = chainringSets.find(cr => cr.name === value)
        setGearRatio({
            ...gearRatio,
            chainringSet: newChainringSet
        })
        setChainringSetSize(newChainringSet?.set ? newChainringSet.set.length : 0)
        if (gearRatio.cogSet?.set && newChainringSet?.set) {
            // @ts-ignore
            setLightest(newChainringSet.set[0] / [...gearRatio.cogSet.set].pop())
            // @ts-ignore
            setHeaviest([...newChainringSet.set].pop() / gearRatio.cogSet.set[0])
        }
    };

    const changeCogSetRestriction = (value: string) => {
        const dropDown = document.getElementById("cogSet" + gearRatiosProps.boxId) as HTMLSelectElement
        if (dropDown !== null) {
            dropDown.selectedIndex = 0;
        }
        setCogSetRestriction(value && parseInt(value) ? parseInt(value) : 0)
    }
    const changeCogSetsState = (value: string) => {
        const newCogSet = cogSets.find(cs => cs.description === value)
        setGearRatio({
            ...gearRatio,
            cogSet: newCogSet
        })
        setCogSetSize(newCogSet?.set ? newCogSet.set.length : 0)
        if (newCogSet?.set && gearRatio.chainringSet?.set) {
            // @ts-ignore
            setLightest(gearRatio.chainringSet.set[0] / [...newCogSet.set].pop())
            // @ts-ignore
            setHeaviest([...gearRatio.chainringSet.set].pop() / newCogSet.set[0])
        }
    };

    const changeWheelSizeState = (value: string) => {
        const dropDown = document.getElementById("tireSize" + gearRatiosProps.boxId) as HTMLSelectElement
        if (dropDown !== null) {
            dropDown.selectedIndex = 0;
        }
        setTireSizes((configData.TireSizes as TireSize[]).filter(ts => value === "" || value == ts.inch + " inch"))
    };

    const etrto2circumference = (etrto: string): number => {
        const [width, diameter] = etrto.split('-')
        return (2 * parseInt(width) + parseInt(diameter)) * Math.PI
    }

    const changeTireSizeState = (value: string) => {
        const newTireSize = tireSizes.find(ts => value === ts.inch + " x " + ts.width + " (" + ts.ETRTO + ")")
        setGearRatio({
            ...gearRatio,
            tireSize: newTireSize
        })
        setTireSize(etrto2circumference(newTireSize ? newTireSize.ETRTO : "0-0"))
    }

    useEffect(() => {
        if (gearRatio.cogSet?.set && gearRatio.chainringSet?.set) {
            // @ts-ignore
            setLightest(gearRatio.chainringSet.set[0] / [...gearRatio.cogSet.set].pop())
            // @ts-ignore
            setHeaviest([...gearRatio.chainringSet.set].pop() / gearRatio.cogSet.set[0])
        }
    }, [gearRatio])

    const calculateGrowth = (a: number, b: number) => {
        return (100 * (b - a) / a).toFixed(0)
    }

    return (
        <div className="min-w-xs max-w-sm my-3">
            <Card>
                {/* close button */}
                <button type="button"
                        onClick={() => gearRatiosProps.hideBox(gearRatiosProps.boxId)}
                        className="ml-auto -mx-3 -my-3 w-9 h-9 bg-white border border-1 border-gray-200 text-gray-400 hover:text-gray-900 rounded-full focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
                        aria-label="Close">
                    <span className="sr-only">Close</span>
                    <CancelIcon/>
                </button>

                {/*velg- en bandenmaat*/}
                <label htmlFor={"wheelSize-" + gearRatiosProps.boxId}
                       className="block mx-5 mt-3 text-sm font-bold text-gray-900">Kies de bandenmaat</label>
                <div className={"flex"}>
                    <select
                        className="w-28 mx-3 my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        onChange={(e) => changeWheelSizeState(e.target.value)}
                        id={"wheelSize" + gearRatiosProps.boxId}>
                        <option value="disabled defaultValue">Velgmaat</option>
                        {
                            wheelSizes
                                .filter(ws => gearRatiosProps.bikeTypes.includes(ws.bikeType) || gearRatiosProps.bikeTypes.length === 0)
                                .map(ws => <option key={ws.inch + ws.bikeType}
                                                   value={ws.inch + " inch"}>{ws.inch + " inch"}</option>)
                        }
                    </select>

                    <select
                        className="w-80 ml-0 mr-3 my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                        onChange={(e) => changeTireSizeState(e.target.value)}
                        id={"tireSize" + gearRatiosProps.boxId}>
                        <option value="disabled defaultValue">Bandmaat</option>
                        {
                            tireSizes
                                .filter(ts => gearRatiosProps.bikeTypes.length === 0 || gearRatiosProps.bikeTypes.includes(ts.bikeType))
                                .map(ts => <option key={ts.ETRTO}
                                                   value={ts.inch + " x " + ts.width + " (" + ts.ETRTO + ")"}>
                                    {ts.inch + " x " + ts.width + " (" + ts.ETRTO + ")"}
                                </option>)
                        }
                    </select>
                </div>

                {/* chainring set selector */}
                <label htmlFor={"chainringSetSizes" + gearRatiosProps.boxId}
                       className="block mx-5 mt-3 text-sm font-bold text-gray-900">Kies de voorbladen</label>
                <div className="flex">
                    <select
                        className="w-28 mx-3 my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        onChange={e => changeChainringSetRestriction(e.target.value)}
                        id={"chainringSetSizes" + gearRatiosProps.boxId}>
                        <option value="disabled defaultValue">Aantal</option>
                        {
                            [...new Set(chainringSets.map(ts => ts.set?.length))]
                                .sort()
                                .map(ts => <option key={ts} value={ts}>{ts}</option>)
                        }
                    </select>
                    <select
                        className="w-80 ml-0 mr-3 my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        onChange={e => changeChainringSetsState(e.target.value)}
                        id={"chainringSet" + gearRatiosProps.boxId}>
                        <option value="disabled defaultValue">Voorblad(en)</option>
                        {
                            chainringSets
                                .filter(crs => chainringSetRestriction === 0 || (crs.set?.length && crs.set?.length === chainringSetRestriction))
                                .map(crs => crs.name)
                                .sort()
                                .map(name => <option key={name} value={name}>{name}</option>)
                        }
                    </select>
                </div>

                {/* cog set selector */}
                <label htmlFor={"cogSet" + gearRatiosProps.boxId}
                       className="block mx-5 text-sm font-bold text-gray-900">Kies de cassette</label>
                <div className="flex">
                    <select
                        className="w-28 mx-3 my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        onChange={e => changeCogSetRestriction(e.target.value)}
                        id={"cogSetSizes" + gearRatiosProps.boxId}>
                        <option value="disabled defaultValue">Aantal</option>
                        {
                            [...new Set(cogSets.map(cs => cs.set?.length))]
                                .sort((a, b) => {
                                    if (!a || !b)
                                        return 0;
                                    else if (a === Infinity)
                                        return 1;
                                    else if (!a || isNaN(a))
                                        return -1;
                                    else
                                        return a - b;
                                })
                                .map(cs => <option key={cs} value={cs}>{cs}</option>)
                        }
                    </select>
                    <select
                        className="w-80 ml-0 mr-3 my-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                        onChange={(e) => changeCogSetsState(e.target.value)}
                        id={"cogSet" + gearRatiosProps.boxId}>
                        <option value="disabled defaultValue">Cassette</option>
                        {
                            cogSets.filter(cs => cogSetRestriction === 0 || cs.set?.length && (cs.set?.length === cogSetRestriction))
                                .map(cs => cs.description)
                                .sort()
                                .map(descr => <option key={descr} value={descr}>{descr}</option>)
                        }
                    </select>
                </div>

                <hr/>

                {/* ratios */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead
                            className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3 px-6">Verhoudingen</th>
                            {
                                (gearRatio.chainringSet && gearRatio.chainringSet.set) &&
                                gearRatio.chainringSet.set.map((cr, index) =>
                                    <th key={cr+index.toString()} scope="col" className="py-3 px-6">
                                        {cr}
                                    </th>
                                )
                            }
                            {
                                [...Array(3 - chainringSetSize).keys()].map((el,index) =>
                                    <th key={el+index.toString()} scope="col" className="py-3 px-6">
                                        &nbsp;
                                    </th>
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (gearRatio.cogSet && gearRatio.cogSet.set) &&
                            gearRatio.cogSet.set.map((cs, index) =>

                                <tr key={cs} className="bg-white border-b">
                                    <th scope="row"
                                        className="py-1 px-6 font-bold text-gray-900 whitespace-nowrap">
                                        {cs}&nbsp;
                                        <span className={"font-medium text-xs ml-10 text-gray-500"}>
                      {gearRatio.cogSet?.set && index > 0 ?
                          "+" + calculateGrowth(gearRatio.cogSet.set[index - 1], cs) + "%" :
                          undefined}
                    </span>
                                    </th>
                                    {
                                        (gearRatio.chainringSet && gearRatio.chainringSet.set) &&
                                        gearRatio.chainringSet.set.map(cr =>
                                            <td key={"" + cs + cr} className="py-1 px-6">
                                                {(cr / cs).toFixed(2)}
                                            </td>
                                        )
                                    }
                                    {
                                        [...Array(3 - chainringSetSize).keys()].map(el =>
                                            <td key={el} className="py-1 px-6">
                                                &nbsp;
                                            </td>
                                        )
                                    }
                                </tr>)
                        }
                        {
                            [...Array(13 - cogSetSize).keys()].map(el =>
                                <tr key={el} className="bg-white border-b">
                                    <td className="py-1 px-6">&nbsp;</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>

                {/* speed */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead
                            className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            {["Snelheid (km/h) bij cadans", "70", "90", "110"].map((txt, index) =>
                                <th key={"kmph"+index} scope="col" className="py-3 px-6">{txt}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="bg-white border-b">
                            <th scope="row"
                                className="py-1 px-6 font-bold text-gray-900 whitespace-nowrap">
                                lichste verzet
                            </th>
                            {(tireSize !== 0 && lightest !== 0) &&
                                <React.Fragment>
                                    {[70, 90, 110].map(cadance =>
                                        <td className="py-1 px-6">
                                            {(cadance * tireSize * lightest * 0.00006).toFixed(1)}
                                        </td>
                                    )}
                                </React.Fragment>
                            }
                        </tr>
                        <tr className="bg-white border-b">
                            <th scope="row"
                                className="py-1 px-6 font-bold text-gray-900 whitespace-nowrap">
                                zwaarste verzet
                            </th>
                            {(tireSize !== 0 && heaviest !== 0) &&
                                <React.Fragment>
                                    {[70, 90, 110].map(cadance =>
                                        <td className="py-1 px-6">
                                            {(cadance * tireSize * heaviest * 0.00006).toFixed(1)}
                                        </td>
                                    )}
                                </React.Fragment>
                            }
                        </tr>
                        </tbody>
                    </table>
                </div>
                &nbsp;
            </Card>

            {/*{"BikeTypes " + JSON.stringify(bikeTypes)}*/}
            {/*{"TireSizes " + JSON.stringify(tireSizes.filter(ts => bikeTypes.includes(ts.bikeType)))}*/}
            {/*{"Wheelsizes " + JSON.stringify(wheelSizes)}*/}
            {/*{"cogetSize " + JSON.stringify(cogSetSize)}*/}
            {/*{"cogSetRestriction " + JSON.stringify(cogSetRestriction)}*/}
        </div>

    )
}
