import * as React from "react"
import {GearRatio, GearRatios, BikeType} from "../components/GearRatios";
import {IconButton} from "../components/IconButton";
import {Layout} from "../components/Layout";
import {AddIcon} from "../icons/AddIcon";
import {ChangeEvent} from "react";
import {CancelIcon} from "../icons/CancelIcon";
import {CheckIcon} from "../icons/CheckIcon";

export default function GearRatio2Page() {
    const [numberOfBoxes, setnumberOfBoxes] = React.useState(2);
    const [selectedBikeTypes, setSelectedBikeTypes] = React.useState(["MTB" as BikeType]);

    const addBox = () => {
        const paragraph = document.getElementById("add-box-paragraph") as HTMLParagraphElement;

        if (paragraph) {
            setnumberOfBoxes(numberOfBoxes + 1)
            paragraph.innerHTML = "<em>Box (<b>achteraan of onderin!</b>) toegevoegd</em>"
            setTimeout(() => {
                paragraph.innerHTML = "<em> Voeg nog een box toe </em>";
            }, 3000);
        }
    }

    const hideBox = (boxId: string) => {
        const box = document.getElementById(boxId);
        if (box) box.style.display = 'none';

    }

    const handleChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
        changeEvent.target.checked ?
            setSelectedBikeTypes([...selectedBikeTypes, changeEvent.target.value as BikeType]) :
            setSelectedBikeTypes(selectedBikeTypes.filter(item => item !== changeEvent.target.value));
    };
    const bikeTypes: BikeType[] = ["MTB", "Race"]//, "Gravel"]

    // @ts-ignore
    return (
        <Layout restrictedWidth={false}
                pageTitle={"Alles over overbrengingsverhoudingen | Steven's bike service"}
                metaDescription={"Op zoek naar de juiste overbrengingsverhoudingen? Bereken ze gemakkelijk via de website van Steven's bike service! Dé MTB specialist!✓"}>
            <div className=" max-w-6xl mx-auto">


                <h1 className={"text-3xl sm:text-4xl md:text-5xl font-bold mt-24 mb-10"}>Overbrenging</h1>

                <p className={"text-xl my-1"}>
                    Hier kun je de overbrengingsverhoudingen zien en vergelijken, met de bijbehorende snelheden.
                </p>

                <div className="md:grid md:grid-cols-2 mt-4 lg:content-between gap-16 ">

                    <ul className="items-center w-full text-sm font-medium text-gray-900 rounded-lg border border-gray-200 sm:flex">
                        {
                            bikeTypes.map(biketype =>
                                <li key={biketype}
                                    className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r ">
                                    <div className="flex items-center pl-3">
                                        <label htmlFor={biketype + "-checkbox-list"}
                                               className="py-3 ml-2 w-full text-sm font-medium text-gray-900 ">{biketype}</label>
                                        <input
                                            type="checkbox"
                                            id={biketype + "-checkbox-list"}
                                            checked={selectedBikeTypes.includes(biketype)}
                                            value={biketype}
                                            onChange={handleChange}
                                            className="w-4 h-4 mx-5 text-blue-600 rounded border-gray-300"/>
                                    </div>
                                </li>
                            )
                        }
                    </ul>

                    <div className={"flex flex-row-reverse mt-3"} onClick={addBox}>
                        <button id={"add-box-button"}
                                type="button"
                                className="bg-white text-gray-400 hover:text-gray-900 h-9 w-9 rounded-full border border-1 border-gray-200 focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100"
                                aria-label="Close">
                            <AddIcon/>
                        </button>
                        <p id={"add-box-paragraph"} className={"text-sm mx-3 font-medium mt-2"}>
                            <em> Voeg een box toe </em>
                        </p>
                    </div>
                </div>
            </div>

                <div className="container mx-auto space-x-5">
                    <div className="flex flex-wrap justify-center">
                        {
                            [...Array(numberOfBoxes).keys()].map(el =>
                                <div id={"box-" + el} key={el} className="mx-2 ">
                                    <GearRatios
                                        bikeTypes={selectedBikeTypes}
                                        boxId={"box-" + el}
                                        hideBox={hideBox}
                                    />

                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={"mt-24"}/>
        </Layout>
    )
}
